import { combineReducers } from 'redux';
import sharedPiecesReducer from './sharedPiecesReducer';
import contactFormReducer from './contactFormReducer';
import newsletterFormReducer from "./newsletterFormReducer";
import footerFormReducer from "./footerFormReducer";

export default combineReducers({
  sharedPieces: sharedPiecesReducer,
  contactForm: contactFormReducer,
  newsletterForm: newsletterFormReducer,
  footerForm: footerFormReducer
});
