import React from 'react';
import he from 'he';

export const SITE_URL = process.env.REACT_APP_SITE_URL;

// For dev API 
// export const SITE_URL = 'https://nurelmpghdev.wpenginepowered.com';

// For prod API 
// export const SITE_URL = 'https://wp-api.nurelm.com';

// For local API 
// export const SITE_URL = 'http://localhost:8080';

//Contact Form:
export const SUBJECT_CHANGED = 'subject_changed';
export const FIRST_NAME_CHANGED = 'first_name_changed';
export const LAST_NAME_CHANGED = 'last_name_changed';
export const EMAIL_CHANGED = 'email_changed';
export const PHONE_CHANGED = 'phone_changed';
export const COMMENT_CHANGED = 'comment_changed';
export const SUBMIT_CONTACT_FORM = 'submit_contact_form';
export const SUCCESS_CONTACT_FORM = 'success_contact_form';
export const ERROR_CONTACT_FORM = 'error_contact_form';

//Contact Form:
export const SUBMIT_NEWSLETTER_FORM = 'SUBMIT_NEWSLETTER_FORM'
export const SUCCESS_NEWSLETTER_FORM = 'SUCCESS_NEWSLETTER_FORM'
export const ERROR_NEWSLETTER_FORM = 'ERROR_NEWSLETTER_FORM'

//Footer Form:
export const SUBMIT_FOOTER_FORM = 'SUBMIT_FOOTER_FORM'
export const SUCCESS_FOOTER_FORM = 'SUCCESS_FOOTER_FORM'
export const ERROR_FOOTER_FORM = 'ERROR_FOOTER_FORM'

export const gTracking = () => {
  /**
   * Google urchin tracking script. Based off:
   * https://support.google.com/google-ads/answer/7012522?hl=en-GB&sjid=7690087920952802704-NA
   */

  function get_tracking_param(p) {
    var match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  }

  function get_expiry_record(value) {
    var expiry_period = 30 * 24 * 60 * 60 * 1000;

    var expiry_date = new Date().getTime() + expiry_period;
    return {
      value: value,
      expiry_date: expiry_date
    };
  }

  function add_tracking_param(param_name) {
    var tracking_param = get_tracking_param(param_name);
    var param_records = null;

    if (tracking_param) {
      param_records = get_expiry_record(tracking_param);
      localStorage.setItem(param_name, JSON.stringify(param_records));
      console.log('storage:', localStorage);
    }

    var param = param_records || JSON.parse(localStorage.getItem(param_name));
    var param_valid = param && new Date().getTime() < param.expiry_date;

    if (param_valid) {
      const field_elements = document.querySelectorAll(".gt_field_" + param_name);
      for (let e of field_elements) {
        console.log('hi e', e)
        const input_field = e.querySelector("input");
        input_field.value = param.value;
      }
    }
  }

  window.addEventListener('load', function() {
    add_tracking_param("source");
    add_tracking_param("medium");
    add_tracking_param("campaign");
    add_tracking_param("keyword");
    add_tracking_param("gclid");
  });
}
// End gTracking

export const htmlDecode = (input) => {
  const version = detectIE();
  if(!version) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }
  else if (input !== undefined) {
    return he.decode(input);
  }
  return input
}


function detectIE() {
  var ua = window.navigator.userAgent;

  var msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    return true;
    // IE 10 or older => return version number
    // return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
    return true;
    // IE 11 => return version number
    // var rv = ua.indexOf('rv:');
    // return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  var edge = ua.indexOf('Edge/');
  if (edge > 0) {
    return true;
   // Edge (IE 12+) => return version number
   // return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
}

export const mapTechCategories = (study) => {
  if(study.acf.technologies !== "") {
    return study.acf.technologies.map((tech, index) => {
      if(index > 0) {
        let text = ", " + tech
        return <span key={index}>{text}</span>;
      }
      return <span key={index}> {tech}</span>;
    })
  }
  return <span> PROJECT</span>;
}

export const getRandomInt = (max) => {
  return Math.floor(Math.random() * max);
}